<template>
	<div class="assets" v-if="$route.name == 'category' && assets && assets.length > 0 || assets && assets.length > 1">
		<hr/>
		<b-row class="pt-4">
			<b-col>
				<h4>Assets</h4>
			</b-col>
		</b-row>
		<b-row class="pb-4">
			<b-col v-for="asset in filtered" :key="asset.id" @click="goToAsset(asset)" class="col-6 col-lg-4 pb-3">
				<div class="item">
					<div class="img" :class="asset.thumbnail_url ? 'no-icon' : ''">
						<img :src="asset.thumbnail_url"/>
					</div>
					<p class="title mb-0 pt-1">{{ asset.title }}</p>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	export default {
		computed: {
			filtered() {
				return this.$route.name == 'category' 
					? this.assets
					: this.assets.filter(a => a.id != this.asset.id )                
			},
			assets() {
				return this.$store.getters.assets
			},
			asset() {
				return this.$store.getters.asset
			},
			password() {
				return this.$store.getters.password
			}
		},
		methods: {
			goToAsset(asset) {
				this.$store.dispatch('loaded', false)
				this.$store.dispatch('asset', { asset_slug: asset.slug, password: this.password })
				.then(() => {
					this.$store.dispatch('loaded', true)
					this.$router.push({ name: 'asset', params: { slug: asset.slug } })
				})
			}
		}
	}
</script>
