var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login py-4" },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { staticClass: "justify-content-center" },
            [
              _c(
                "b-col",
                {
                  staticClass: "text-center",
                  attrs: { cols: "10", sm: "8", md: "5", lg: "4" }
                },
                [
                  _c("h1", [_vm._v("Låst sida")]),
                  _c("p", [
                    _vm._v("Ange korrekt lösenord för att se innehållet")
                  ]),
                  _c(
                    "b-form",
                    {
                      staticClass: "pt-3",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.login($event)
                        }
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "password",
                                  autocomplete: "password",
                                  placeholder: "Lösenord"
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password"
                                }
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-button",
                                    { attrs: { type: "submit" } },
                                    [_vm._v("Bekräfta")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }