<template>
    <div class="">
        <h5 class="chapter-heading">{{lang == 'en'? 'Chapters' : 'Kapitel'}}</h5>
		<b-row>
			<b-col v-for="(chunk, index) in productChunks" :key="index">
				<div class="" v-for="(chapter, key) in chunk" :key="'chapter'+key">
					<div @click="changeChapter(chapter)" class="d-flex align-items-center">
						<img class="chapter-icon" src="../assets/img/arrow.png">
						<span class="pl-1 chapter-link overflow-ellipsis" v-if="chapter.label == 'Introduktion'">
							{{lang == 'en'? 'Opening ' : chapter.label}}
						</span>
						<span class="pl-1 chapter-link overflow-ellipsis" v-else-if="chapter.label == 'Summering'">
							{{lang == 'en'? 'Summary ' : chapter.label}}
						</span>
						<span class="pl-1 chapter-link overflow-ellipsis" v-else>
							{{chapter.label}}
						</span>
					</div>
				</div>
			</b-col>
		</b-row>
    </div>
</template>

<script>
export default {
	data() {
		return {
			lang: 'sv'
		}
	},
    computed: {
        asset() {
            return this.$store.getters.asset;
        },
		productChunks(){
			return _.chunk(this.asset.chapters, 3);
		}
    },
    methods: {
        changeChapter(chapter) {
            try {
                let data = { currentTime: chapter.timecode }
                let frame = document.querySelector('iframe');
                frame.contentWindow.postMessage(data, '*')
            } catch (err) {
                console.log(err);
            }

        }
    },
	mounted() {
		this.lang = new URLSearchParams(window.location.search).get('lang');
	}
}
</script>

<style lang="scss" scoped>
    .chapter-link {
       &:hover {
        cursor: pointer;
       }
    }
    .chapter-heading {
        font-weight: 600;
    }
    .chapter-icon {
      width: 15px;
      height: 15px;
    }
</style>
