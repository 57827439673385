<template>
	<div id="category">
		<b-container v-if="category && loaded">
			<b-row class="pb-3">
				<b-col class="text-center">
					<h3>{{ category.title }}</h3>
				</b-col>
			</b-row>
			<div class="content">
				<Categories></Categories>
				<Assets></Assets>
			</div>
		</b-container>
		<b-container v-else>
			<Loading></Loading>
		</b-container>
	</div>
</template>
<script>
	import Categories 	from '@/components/Categories.vue'
	import Assets 		from '@/components/Assets.vue'
	import Loading 		from '@/components/Loading.vue'
	export default {
		name: 'Category',
		components: {
			Categories,
			Assets,
			Loading
		},
		computed: {
			category() {
				return this.$store.getters.category
			},
			password() {
				return this.$store.getters.password
			},
			loaded() {
				return this.$store.getters.loaded
			}
		},
		methods: {
			getCategory() {
				this.$store.dispatch('loaded', false)
				this.$store.dispatch('category', { category_slug : this.$route.params.slug , password: this.password })
				.then(response => {
					return this.$store.dispatch('categories', { category_id: response.id, password: this.password })
				})
				.then(() => {
					return this.$store.dispatch('assets', { category_id: this.category.id, password: this.password })
				})
				.catch(err => {
					this.handleError(err.response.status)
				})
				.finally(() => {
					this.$store.dispatch('loaded', true)
				})
			},
			handleError(status) {
				if (status === 403) {
					this.$router.push({ name: 'login', params: { slug: this.$route.params.slug, name: this.$route.name }})
				} else {
					this.$router.push({ name: 'notfound' })
				}
			}
		},
		mounted() {
			this.getCategory()
		},

		watch: {
			$route(to) {
				if (to.name === "category") {
					this.getCategory()
				}
			}
		}
	}
</script>
