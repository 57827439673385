var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "category" } },
    [
      _vm.category && _vm.loaded
        ? _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "pb-3" },
                [
                  _c("b-col", { staticClass: "text-center" }, [
                    _c("h3", [_vm._v(_vm._s(_vm.category.title))])
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content" },
                [_c("Categories"), _c("Assets")],
                1
              )
            ],
            1
          )
        : _c("b-container", [_c("Loading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }