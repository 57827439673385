var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "asset" } }, [
    _vm.category && _vm.asset && _vm.loaded
      ? _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "b-row",
              {
                staticClass: "pb-3 mx-0 w-100",
                attrs: { "align-h": "center" }
              },
              [
                _c(
                  "b-col",
                  {
                    staticClass: "px-0",
                    attrs: {
                      cols: "12",
                      md:
                        _vm.asset.ratio === "1:1"
                          ? "9"
                          : _vm.asset.ratio === "4:3"
                          ? "11"
                          : "12",
                      lg:
                        _vm.asset.ratio === "1:1"
                          ? "8"
                          : _vm.asset.ratio === "4:3"
                          ? "10"
                          : "12"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "bg-black embed-responsive",
                        class: _vm.getRatio().class
                      },
                      [
                        _c("iframe", {
                          staticClass: "embed-responsive-item",
                          attrs: {
                            src: _vm.src,
                            allowfullscreen: "allowfullscreen"
                          }
                        })
                      ]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "w-100" },
              [
                _c(
                  "b-col",
                  { staticClass: "pt-2", attrs: { cols: "12" } },
                  [_c("Chapters")],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _c("div", [_c("Loading")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }