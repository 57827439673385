<template>
	<div id="asset">
		<div class="content"  v-if="category && asset && loaded">
			<b-row class="pb-3 mx-0 w-100" align-h="center">
				<b-col 	cols="12"
						class="px-0"
						:md="asset.ratio === '1:1' ? '9' : asset.ratio === '4:3' ? '11' : '12'"
						:lg="asset.ratio === '1:1' ? '8' : asset.ratio === '4:3' ? '10' : '12'">
					<div class="bg-black embed-responsive" :class="getRatio().class">
						<iframe class="embed-responsive-item" :src="src" allowfullscreen="allowfullscreen"></iframe>
					</div>
				</b-col>
			</b-row>
			<b-row class="w-100">
				<b-col cols="12" class="pt-2">
					<Chapters/>
				</b-col>
			</b-row>
		</div>
		<div v-else>
			<Loading/>
		</div>
	</div>
</template>
<script>
	import Loading 		from '@/components/Loading.vue'
	import Chapters 	from '@/components/Chapters.vue';

	export default {
		components: {
			Loading,
			Chapters
		},
		computed: {
			src() {
				return this.asset.type === 4 && this.asset.broadcasting !== 3
					? this.env.VUE_APP_PLAYER_SRC + '?client_key=' + this.asset.client_key + '&asset_key=' + this.asset.asset_key + '&live=true' + this.getRatio().parameter + "&fetch=true"
					: this.env.VUE_APP_PLAYER_SRC + '?api_key=' + this.env.VUE_APP_API_KEY +'&slug=' + this.asset.slug + this.getRatio().parameter + "&fetch=true"
			},
			env() {
				return process.env
			},
			category() {
				return this.$store.getters.category
			},
			asset() {
				return this.$store.getters.asset
			},
			password() {
				return this.$store.getters.password
			},
			loaded() {
				return this.$store.getters.loaded
			}
		},
		methods: {
			getAsset() {
				this.$store.dispatch('loaded', false)
				this.$store.dispatch('asset', { asset_slug: this.getSlug(), password: this.password })
				.then(response => {
					return this.$store.dispatch('category', { category_slug : response.category.slug ? response.category.slug : '_root',  password: this.password })
				})
				.then(response => {
					return this.$store.dispatch('assets', { category_id : response.id, password: this.password })
				})
				.catch(err => {
					this.handleError(err.response.status)
				})
				.finally(() => {
					this.$store.dispatch('loaded', true)
				})
			},
			getSlug() {
				const path = window.location.pathname
				const slug = this.$route.params.slug ? this.$route.params.slug : path.substring(path.lastIndexOf('/') + 1)
				return slug
			},
			handleError(status) {
				if (status === 403) {
					this.$router.push({ name: 'login', params: { slug: this.getSlug(), name: this.$route.name }})
				} else {
					this.$router.push({ name: 'notfound' })
				}
			},
			getRatio() {
				if (this.asset.ratio === '1:1')
					return { parameter: '&format=f1by1', class: 'embed-responsive-1by1' }
				else if (this.asset.ratio === '4:3')
					return { parameter: '&format=f4by3', class: 'embed-responsive-4by3' }
				else if (this.asset.ratio === '21:9')
					return { parameter: '&format=f21by9', class: 'embed-responsive-21by9' }
				else
					return { parameter: '&format=f16by9', class: 'embed-responsive-16by9' }
			}
		},
		mounted() {
			this.getAsset()
		}
	}
</script>
