var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loading py-4" },
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "text-center" }, [
            _c("span", {
              staticClass: "dot",
              class: _vm.loaded > 0 ? "" : "hidden"
            }),
            _c("span", {
              staticClass: "dot",
              class: _vm.loaded > 1 ? "" : "hidden"
            }),
            _c("span", {
              staticClass: "dot",
              class: _vm.loaded > 2 ? "" : "hidden"
            })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }