<template>
	<div class="loading py-4">
		<b-row>
			<b-col class="text-center">
				<span class="dot" :class="loaded > 0 ? '' : 'hidden'"></span>
				<span class="dot" :class="loaded > 1 ? '' : 'hidden'"></span>
				<span class="dot" :class="loaded > 2 ? '' : 'hidden'"></span>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loaded: 0
			}
		},
		methods: {
			initLoading() {
				setInterval(function() {
					if (this.loaded < 3)
						this.loaded++
					else
						this.loaded = 0
				}.bind(this), 1000)
			}
		},
		mounted() {
			this.loaded = 0
			this.initLoading()
		}
	}
</script>
