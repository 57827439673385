<template>
	<div class="categories" v-if="categories && categories.length > 0">
		<hr/>
		<b-row class="pt-4">
			<b-col>
				<h4>Categories</h4>
			</b-col>
		</b-row>
		<b-row class="pb-4">
			<b-col v-for="category in filtered" :key="category.id" @click="goToCategory(category)" class="col-6 col-lg-4 pb-3">
				<div class="item">
					<div class="img" :class="category.thumbnail_url ? 'no-icon' : ''">
						<img :src="category.thumbnail_url" v-if="category.thumbnail_url"/>
					</div>
					<p class="title mb-0 pt-1">{{ category.title }}</p>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	export default {
		computed: {
			filtered() {
				return this.$route.name == 'category' 
					? this.categories
					: this.categories.filter(c => c.id != this.category.id )                
			},
			categories() {
				return this.$store.getters.categories
			},
			category() {
				return this.$store.getters.category
			},
			password() {
				return this.$store.getters.password
			}
		},
		methods: {
			goToCategory(category) {
				this.$store.dispatch('category', { category_slug: category.slug, password: this.password })
				.then(() => {
					this.$router.push({ name: 'category', params: { slug: category.slug } })
				})
			}
		}
	}
</script>
