var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("h5", { staticClass: "chapter-heading" }, [
        _vm._v(_vm._s(_vm.lang == "en" ? "Chapters" : "Kapitel"))
      ]),
      _c(
        "b-row",
        _vm._l(_vm.productChunks, function(chunk, index) {
          return _c(
            "b-col",
            { key: index },
            _vm._l(chunk, function(chapter, key) {
              return _c("div", { key: "chapter" + key }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-items-center",
                    on: {
                      click: function($event) {
                        return _vm.changeChapter(chapter)
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "chapter-icon",
                      attrs: { src: require("../assets/img/arrow.png") }
                    }),
                    chapter.label == "Introduktion"
                      ? _c(
                          "span",
                          {
                            staticClass: "pl-1 chapter-link overflow-ellipsis"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.lang == "en" ? "Opening " : chapter.label
                                ) +
                                " "
                            )
                          ]
                        )
                      : chapter.label == "Summering"
                      ? _c(
                          "span",
                          {
                            staticClass: "pl-1 chapter-link overflow-ellipsis"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.lang == "en" ? "Summary " : chapter.label
                                ) +
                                " "
                            )
                          ]
                        )
                      : _c(
                          "span",
                          {
                            staticClass: "pl-1 chapter-link overflow-ellipsis"
                          },
                          [_vm._v(" " + _vm._s(chapter.label) + " ")]
                        )
                  ]
                )
              ])
            }),
            0
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }